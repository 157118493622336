import React, { FC } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { Button } from 'wix-ui-tpa';
// import { Button } from 'wix-ui-tpa/cssVars';
import { st, classes } from './Widget.st.css';
import styles from './Widget.scss';

const Widget: FC<WidgetProps<{}>> = () => {
  return (
    <div
      className={`${styles.root} ${st(classes.root, {})}`}
      data-hook="MyWidget-wrapper"
    >
      <div className={classes.header}>
        <h2 data-hook="app-title">WIDGET TEXT</h2>
      </div>
      <Button className={classes.mainButton}>Click me</Button>
    </div>
  );
};

export default Widget;
